@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

body {
  font-family: 'Roboto', sans-serif; /* Use a modern, professional font */
  line-height: 1.6; /* Improve readability */
  margin: 0;
  padding: 0;
  color: #333; /* Consistent text color */
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-header {
  background: linear-gradient(to right, #2c3e50, #4ca1af);
  padding: 1rem 2rem;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  color: white;
  font-size: 1.8rem;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li {
  margin-left: 2rem;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: 400;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: #3498db;
}

main {
  padding: 2rem;
}

.hero, .features, .about, .contact {
  border-radius: 20px;
  margin: 2rem;
  overflow: hidden; /* This ensures content doesn't spill out of rounded corners */
}

.hero {
  background: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
  padding: 6rem 2rem;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.features {
  background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%);
  padding: 6rem 2rem;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.about, .contact {
  background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  padding: 6rem 2rem;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

h1 {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #2c3e50;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
  line-height: 1.2;
}

h2 {
  font-size: 2.2rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  line-height: 1.3;
}

.hero p {
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.cta-button, .contact-button {
  background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  border: none;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 25px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.cta-button:hover, .contact-button:hover {
  background: linear-gradient(to right, #0072ff 0%, #00c6ff 100%);
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0,0,0,0.15);
}

.features {
  background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%);
  padding: 6rem 2rem;
}

.feature-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.feature-item {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  flex: 1 1 200px; /* Flex-grow, flex-shrink, flex-basis */
  max-width: 300px; /* Optional max-width for larger screens */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.feature-item h3 {
  font-size: 1.25rem; /* Default font size */
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .feature-item h3 {
    font-size: 1.1rem; /* Adjust font size for tablets */
  }

  .feature-item p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .feature-item h3 {
    font-size: 1rem; /* Further adjust font size for mobile phones */
  }

  .feature-item p {
    font-size: 0.85rem;
  }
}

.about, .contact {
  background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  padding: 6rem 2rem;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
}

.about p, .contact p {
  max-width: 800px;
  margin: 0 auto 2rem;
  font-size: 1.05rem;
  line-height: 1.6;
}

footer {
  background: linear-gradient(to right, #2c3e50, #4ca1af);
  color: white;
  padding: 2rem;
  font-size: 0.9rem;
}

/* Add this new style for the CreateJobDescription component */
.create-job-description {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: calc(100vh - 60px); /* Adjust based on your header height */
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create-job-description h1 {
  margin-bottom: 1.5rem;
}

.create-job-description p {
  margin-bottom: 2rem;
  max-width: 600px;
  text-align: center;
  line-height: 1.6;
}

/* Add this new style for the main content area */
.home-content {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 40px; /* Increase padding for a spacious layout */
  min-height: calc(100vh - 60px); /* Adjust based on your header height */
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 60px);
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.login form {
  display: flex;
  flex-direction: column;
  width: 300px;
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.login input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login button {
  background: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  border: none;
  color: white;
  padding: 0.75rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.login button:hover {
  background: linear-gradient(to right, #0072ff 0%, #00c6ff 100%);
}

/* job-description/src/App.css */
.create-job-description-container {
  max-width: 600px; /* Set a max width for the form */
  margin: 20px auto; /* Center the form */
  padding: 20px; /* Add padding */
  border: 1px solid #ccc; /* Optional border */
  border-radius: 8px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.job-form {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button {
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #45a049; /* Darker green */
}

.cta-button {
  display: inline-block;
  margin-top: 15px;
  text-decoration: none;
  color: #007BFF; /* Link color */
  padding: 10px 15px;
  border: 1px solid #007BFF;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: #007BFF;
  color: white;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

.animated-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.animated-input:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 10px;
}

.cta-button:hover {
  background-color: #2980b9;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #45a049;
}

/* Responsive styles */
@media (max-width: 768px) {
  .step-container {
    padding: 15px;
    margin: 10px;
  }

  h2 {
    font-size: 1.25rem;
  }

  .cta-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .feature-grid {
    display: flex;
    flex-direction: column;
  }

  .feature-item {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 12px;
    padding: 6px 12px;
  }
}

/* Base styles */
.hero h1 {
  font-size: 2rem; /* Default font size for larger screens */
  margin: 0;
  padding: 0.5rem 0;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 1.5rem; /* Adjust font size for tablets */
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 1.2rem; /* Further adjust font size for mobile phones */
  }
}

/* src/App.css */

/* Base styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif; /* Use a modern, professional font */
  line-height: 1.6; /* Improve readability */
  color: #333; /* Consistent text color */
}

.App-header {
  background-color: #2c3e50;
  padding: 10px 20px;
}

.navbar {
  display: flex;
  justify-content: center; /* Center the nav-links horizontally */
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff; /* Muted but more contrastive color */
}

.menu-toggle {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #ffffff;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  text-decoration: none;
  color: #000000; /* Black text color */
  padding: 10px 20px;
  border-radius: 0; /* Rectangular shape */
  transition: box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for visibility */
}

.nav-links a:hover {
  color: #000000; /* Ensure text color remains black on hover */
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.sign-in-button {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sign-in-button:hover {
  background: linear-gradient(135deg, #2575fc, #6a11cb);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Responsive styles */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav-links {
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #2c3e50;
    width: 100%;
    display: none;
  }

  .nav-links.show {
    display: flex;
  }

  .nav-links li {
    margin: 10px 0;
  }
}

/* Base styles */
.home-content {
  padding: 40px; /* Increase padding for a spacious layout */
}

.hero, .features, .about, .contact {
  background-color: #ffffff;
  padding: 30px; /* Increase padding inside sections */
  border-radius: 12px; /* Rounded corners for a bubbly look */
  margin-bottom: 30px; /* Space between sections */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
}

.hero h1, .features h2, .about h2, .contact h2 {
  font-size: 2.5rem; /* Larger font size for headings */
  margin-bottom: 20px; /* Space below headings */
  color: #2c3e50; /* Consistent heading color */
  text-align: center; /* Center align headings */
}

.hero p, .features p, .about p, .contact p {
  font-size: 1.2rem; /* Slightly larger font size for paragraphs */
  max-width: 800px; /* Limit width for better readability */
  margin: 0 auto 20px; /* Center align and space below paragraphs */
  line-height: 1.8; /* Increase line height for readability */
  text-align: center; /* Center align text */
}

.cta-button {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 12px 24px;
  border-radius: 25px;
  text-decoration: none;
  transition: background 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.feature-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.feature-item {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  flex: 1 1 250px;
  max-width: 350px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.feature-item h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.feature-item p {
  font-size: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .home-content {
    padding: 20px;
  }

  .hero, .features, .about, .contact {
    padding: 20px;
  }

  .hero h1, .features h2, .about h2, .contact h2 {
    font-size: 2rem;
  }

  .hero p, .features p, .about p, .contact p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .home-content {
    padding: 15px;
  }

  .hero, .features, .about, .contact {
    padding: 15px;
  }

  .hero h1, .features h2, .about h2, .contact h2 {
    font-size: 1.5rem;
  }

  .hero p, .features p, .about p, .contact p {
    font-size: 0.9rem;
  }
}

.about ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove default padding */
}

.about li {
  margin-bottom: 10px; /* Add some spacing between items */
}