.step-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

.highlight {
  color: red;
}

.popup {
  position: fixed;
  top: 10%; /* Adjust this value to position the popup at the desired height */
  left: 50%;
  transform: translateX(-50%); /* Center the popup horizontally */
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup p {
  margin: 0 0 10px;
}

.popup button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.popup button:hover {
  background-color: #2980b9;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 10px;
}

.cta-button:hover {
  background-color: #2980b9;
}

.animated-input {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* src/createFlow/Step6.css */
.loading-container {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100vh;
}
  
.spinner {
border: 16px solid #f3f3f3; /* Light grey */
border-top: 16px solid #3498db; /* Blue */
border-radius: 50%;
width: 120px;
height: 120px;
animation: spin 2s linear infinite;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.step-container {
padding: 20px;
background-color: #fff;
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
max-width: 800px;
margin: 20px auto;
}

.summary-table {
display: table;
width: 100%;
margin-bottom: 20px;
}

.summary-row {
display: table-row;
}

.summary-key, .summary-value {
display: table-cell;
padding: 10px;
border-bottom: 1px solid #ddd;
}

.summary-key {
font-weight: bold;
background-color: #f9f9f9;
}

.job-description-container {
margin-top: 20px;
}

.job-description {
font-family: 'Arial', sans-serif;
line-height: 1.6;
color: #333;
}

.job-description p {
margin: 10px 0;
}

.cta-button {
display: inline-block;
padding: 10px 20px;
background-color: #3498db;
color: white;
border: none;
border-radius: 4px;
font-size: 16px;
cursor: pointer;
margin-top: 20px;
margin-right: 10px;
}

.cta-button:hover {
background-color: #2980b9;
}
